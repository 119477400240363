import { Breadcrumbs } from '~/plugins/breadcrumbs'
import { CACHE_SETTINGS } from '~/utils/cache'
import { vLog } from '~/utils/vLog'
import { adaptiveCtx } from '~/utils/v'

const _mxMetaDefault = {
  title: 'Hire boats directly from the local owner | Tubber',
  description: 'Tubber description'
}

export const mxGetMeta = async function (ctx, MX = {}) {
  ctx = adaptiveCtx(ctx, { forClient: true })
  if (!MX?.meta) {
    vLog(ctx, 'Meta settings not found')
    return
  }
  let path = null
  switch (MX?.meta?.method) {
    case 'static':
      vLog(ctx, 'Static meta')
      path = MX?.meta?.path || ctx.$route.path
      try {
        const dtS = await ctx.$cacheFetch({ key: `getStaticTags?path=${path}`, expire: CACHE_SETTINGS.metaTime }, async () => {
          return await ctx.$aProxy.get('/getTags', { params: { path } }).then((resp) => {
            return ctx.$store.dispatch('getStaticTags', { path: resp.data.encodedUrl })
          })
        })
        await ctx.$store.dispatch('setPageTags', dtS)
      } catch (e) {
        ctx.error({
          statusCode: 500,
          message: 'Data loading error, request: ' + `getStaticTags?path=${path}`,
          data: e
        })
      }
      break
    case 'dynamic':
      path = MX?.meta?.path || ctx.$route.path
      const type = MX?.meta?.type
      const idx = MX?.meta?.idx
      const params = MX?.meta?.params

      try {
        const dtD = await ctx.$store.dispatch('getDynamicTags', { path, type, idx, params })
        await ctx.$store.dispatch('setPageTags', dtD)
      } catch (e) {
        ctx.error({
          statusCode: 500,
          message: 'Data loading error, request: ' + `getDynamicTags?path=${path}`,
          data: e
        })
      }
      break
    default:
      break
  }

  await mxMetaBase(ctx, MX)
  await mxMetaBreadcrumbs(ctx, MX)
}

const mxFindByLang = function (ctx, obj) {
  ctx = adaptiveCtx(ctx, { forClient: true })
  const locale = ctx.$store.getters['lang/locale']
  const defaultLocale = ctx.$store.getters['lang/defaultLocale']

  if (obj[locale]) {
    return obj[locale]
  }
  if (obj[defaultLocale]) {
    return obj[defaultLocale]
  }
  return {}
}

const mxMetaBase = async function (ctx, MX) {
  ctx = adaptiveCtx(ctx, { forClient: true })

  const pageData = ctx.$store.getters.pageData
  if (!pageData?.data) {
    ctx.$store.commit('SET_META_DATA', _mxMetaDefault)
    return _mxMetaDefault
  }

  const title = mxFindByLang(ctx, pageData?.data).title || _mxMetaDefault.title
  const description = mxFindByLang(ctx, pageData?.data).description || _mxMetaDefault.description
  const image = pageData?.image

  const result = {
    title,
    meta: [
      { hid: 'description', name: 'description', content: description },
      { name: 'twitter:title', content: title },
      { name: 'og:title', property: 'og:title', content: title },

      { name: 'twitter:description', content: description },
      { name: 'og:description', property: 'og:description', content: description },
      { hid: 'description', name: 'description', content: description },
      { name: 'twitter:image', content: image },
      { property: 'og:image', content: image },
    ],
    link: []
  }

  const page = MX?.meta?.params?.page

  result.link.push({
    rel: 'alternate',
    href: pageData?.x_default ? pageData?.x_default + (page ? `${page}/` : '') : `${process.env.appUrl}/en/`,
    hreflang: 'x-default'
  })

  if (pageData?.hreflangs?.length) {
    pageData.hreflangs.forEach((hl) => {
      if (['ru', 'ru-RU', 'zh', 'zh-CN'].includes(hl.lang)) {
        return
      }
      switch (ctx.route.name) {
        case 'guides':
          return
          break
        default:
          break
      }

      if (hl.url) {

        const hreflang = rLang[hl.lang] || hl.lang

        result.link.push({
          rel: 'alternate',
          href: decodeURI(hl.url) + (page ? `${page}/` : ''),
          hreflang
        })
      }
    })
  }

  await ctx.$store.dispatch('setMetaHead', {
    data: result
  })

  ctx.$store.commit('SET_META_DATA', result)
  return result
}

const mxMetaBreadcrumbs = async function (ctx, MX) {
  ctx = adaptiveCtx(ctx, { forClient: true })

  const pageData = ctx.$store.getters.pageData
  if (!pageData?.data) {
    return _mxMetaDefault
  }
  const title = mxFindByLang(ctx, pageData?.data)?.title || _mxMetaDefault.title

  if (MX?.meta?.breadcrumbs === true) {
    const crubms = Breadcrumbs.get(ctx.app.i18n, 'page', ctx.$route, {
      title,
      url: ctx.$route.path
    })
    await ctx.$store.dispatch('setBreadcrumbs', crubms)
  }
}

export default {
  computed: {
    // MX () {
    //   return {
    //     meta: {
    //       breadcrumbs: true,
    //     },
    //     // meta: {
    //     //   method: 'dynamic',
    //     //   method: 'static',
    //     //   path: '/en/booking/123/details',
    //     //   type: 'booking',
    //     //   idx: '123'
    //     //   breadcrumbs: true,
    //     // }
    //   }
    // },
    _mxMeta () {
      return this.$store.state.metaData
    }
  },
  head () {
    return {
      title: this._mxMeta.title,
      link: this._mxMeta.link
    }
  }
}

export const rLang = {
  'us': 'en-us',
  'en': 'en-gb'
}
