var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formObs",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{staticClass:"form search-bar search-bar-inputs",class:[("form--" + _vm.type), ("search-bar--" + (_vm.$store.state.appTheme) + "-theme")],on:{"keydown":function($event){return _vm.form.onKeydown($event)}}},[_c('div',{staticClass:"form__container"},[(_vm.type === 'search' && !['destination', 'destination-location', 'boat-type', 'brand', 'sailing-holidays', 'sailing-with-skipper'].includes(_vm.$route.name))?[_c('div',{staticClass:"form__body form__body--search-mobile br-down--sm",on:{"click":function($event){return _vm.$modal.show('search-modal')}}},[_c('div',[_c('v-icon',{attrs:{"name":"v-search-outlined"}}),(_vm.location)?_c('span',[_vm._v(_vm._s(_vm.location.name || _vm.rawInput))]):_vm._e()],1),_c('div',[_c('v-icon',{attrs:{"name":"v-calendar"}}),(_vm.getCurrentDate(true)[0] != 'Invalid Date' && _vm.getCurrentDate(true)[1] != 'Invalid Date')?_c('span',[_vm._v(" "+_vm._s(_vm.getCurrentDate(true)[0])+" - "+_vm._s(_vm.getCurrentDate(true)[1])+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t('any-date')))])],1),_c('div',[_c('v-icon',{attrs:{"name":_vm.getBoatTypeIcon,"fallback":"bt-catamaran"}}),_c('span',[_vm._v(_vm._s(_vm.getBoatTypeTitle))])],1)])]:_vm._e(),(_vm.type === 'modal')?[_c('div',{staticClass:"form__body form__body--modal"},[_c('validation-provider',{staticClass:"location search-bar-input search-bar-input--location search-bar-input--modal",attrs:{"rules":_vm.formRules.location,"name":"location","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
return [_c('div',{staticClass:"prevent-only-mobile",on:{"click":_vm.inputClickHandler}}),_c('v-input',{ref:"location",attrs:{"name":"location","placeholder":_vm.$t('choose-where-you-want-to-go'),"title":_vm.$t('choose-a-destination'),"icon":"v-location","icon-pos":"left","type":"list","list":_vm.autocomplete.data,"front-filter-list":false,"list-loading":_vm.autocomplete.loading,"form":_vm.form,"required":required,"errors":errors,"error-text-rules":"no-errors","legend":true,"show-list-stop":_vm.$store.state.breakpoint.screen.widthMinCalc <= 767,"focus-after-clear":_vm.$store.state.breakpoint.screen.widthMinCalc > 767,"set-from-list-on-blur":_vm.setFromListOnBlur,"list-serializer":_vm.highlightInputInList,"clear-icon":""},on:{"cleared":function($event){return _vm.$modal.show('search-destination-input-modal')},"listItemSelected":function (data) { return _vm.input = data; }},nativeOn:{"keyup":function($event){return _vm.search.apply(null, arguments)}},scopedSlots:_vm._u([{key:"list",fn:function(ref){
var getFilteredList = ref.getFilteredList;
var setFromList = ref.setFromList;
var isShow = ref.isShow;
return [(isShow)?[_vm._l((getFilteredList),function(item,idx){return [_c('div',{key:((item.url) + "-" + idx),staticClass:"v-input-list__item",class:{
                        'title': ['popular'].includes(item.type),
                        'no-found': ['no-found'].includes(item.type),
                      },attrs:{"data-id":item.id,"data-value":item.value},on:{"click":function($event){return setFromList({id: item.id, name: item.raw, url: item.slug})}}},[(['no-found'].includes(item.type))?[_c('v-icon',{attrs:{"name":"v2-search"}}),_vm._v(" "+_vm._s(_vm.$t('no-matches-were-found'))+" ")]:(['popular'].includes(item.type))?[_vm._v(" "+_vm._s(_vm.$t('most-popular-destinations'))+" ")]:[_c('div',{staticClass:"icon"},[(_vm.getCodeByItem(item))?_c('v-img',{attrs:{"is-lazy-load":false,"src":("/flags/" + (_vm.getCodeByItem(item).toLowerCase()) + ".svg")}}):(_vm.getIconByLocationType(item.type))?_c('v-icon',{attrs:{"name":_vm.getIconByLocationType(item.type)}}):_vm._e()],1),_c('div',{staticClass:"name"},[_c('span',{staticClass:"title",class:item.type,domProps:{"innerHTML":_vm._s(item.highlight || item.name)}}),_c('span',{staticClass:"count"},[_vm._v(" ("+_vm._s(_vm._f("numberWithCommas")(item.boat_count))+")")])]),_c('div',{staticClass:"type"},[_vm._v(" "+_vm._s(_vm.$t(item.type))+" ")])]],2)]})]:_vm._e()]}}],null,true),model:{value:(_vm.rawInput),callback:function ($$v) {_vm.rawInput=$$v},expression:"rawInput"}})]}}],null,true)}),_c('validation-provider',{staticClass:"date  search-bar-input search-bar-input--date search-bar-input--modal",attrs:{"rules":_vm.formRules.date,"name":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var required = ref.required;
return [_c('v-date',{attrs:{"name":"date","title":_vm.$t('select-date-title'),"placeholder":_vm.$t('select-date'),"clearable":true,"icon":"select-arrow","icon-pos":"right","start-date":_vm.date.start,"end-date":_vm.date.end,"min-date":_vm.date.min,"max-date":_vm.date.max,"disabled-dates":_vm.disabledBeforeToday,"form":_vm.form,"required":required,"errors":errors,"error-text-rules":"no-errors","range":"","dp-popup-classes":{'dp-search-bar': true},"legend":true},on:{"close":_vm.onCloseDate},scopedSlots:_vm._u([{key:"dp-header",fn:function(){return [_c('div',[_c('v-icon',{attrs:{"name":"fa-info-circle"}}),_vm._v(" "+_vm._s(_vm.$t('saturdays-offer-best-prices'))+" ")],1)]},proxy:true}],null,true),model:{value:(_vm.dateModel),callback:function ($$v) {_vm.dateModel=$$v},expression:"dateModel"}})]}}],null,true)}),_c('validation-provider',{staticClass:"boat_type search-bar-input search-bar-input--boat_type search-bar-input--modal",attrs:{"rules":_vm.formRules.boat_type,"name":"boat_type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var required = ref.required;
return [_c('v-select',{ref:"types",staticClass:"boat_type list-with-icons",attrs:{"name":"boat_type","title":_vm.$t('select-type-of-boat'),"placeholder":_vm.$t('any-types'),"options":_vm.boatTypes,"label-params":['name'],"icon":_vm.getBoatTypeIcon,"icon-fallback":"bt-catamaran","icon-pos":"left","form":_vm.form,"required":required,"errors":errors,"legend":true,"error-text-rules":"no-errors"},scopedSlots:_vm._u([{key:"list",fn:function(ref){
                      var getPlaceholder = ref.getPlaceholder;
                      var selectPl = ref.selectPl;
                      var setActive = ref.setActive;
                      var options = ref.options;
                      var getLink = ref.getLink;
                      var itemType = ref.itemType;
                      var labelParams = ref.labelParams;
                      var param = ref.param;
return [_vm._l((getPlaceholder),function(option,i){return (selectPl)?_c('li',{key:("select_pl_" + i),staticClass:"v-select__placeholder",on:{"click":function($event){$event.stopPropagation();return setActive(option)}}},[_vm._v(" "+_vm._s(option.name)+" ")]):_vm._e()}),_vm._l((options),function(option,i){return _c('li',{key:("select_" + i),class:itemType,on:{"click":function($event){$event.stopPropagation();return setActive(option)}}},[_c('v-icon',{attrs:{"name":option.icon,"fallback":"bt-catamaran"}}),_c('span',[_vm._v(" "+_vm._s(!labelParams.length ? option.name : labelParams.reduce(function (option, param) {return option[param]}, option))+" ")])],1)})]}}],null,true),model:{value:(_vm.typeModel),callback:function ($$v) {_vm.typeModel=$$v},expression:"typeModel"}})]}}],null,true)})],1)]:_vm._e(),(_vm.type !== 'modal')?[_c('div',{staticClass:"form__body",class:{'br-up--sm': _vm.type === 'search' && !['destination', 'destination-location', 'boat-type', 'brand', 'sailing-holidays', 'sailing-with-skipper'].includes(_vm.$route.name)}},[_c('validation-provider',{staticClass:"location search-bar-input search-bar-input--location",attrs:{"rules":_vm.formRules.location,"name":"location","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var required = ref.required;
return [_c('div',{staticClass:"prevent-only-mobile",on:{"click":_vm.inputClickHandler}}),_c('v-input',{ref:"location",attrs:{"name":"location","placeholder":_vm.$t('choose-where-you-want-to-go'),"title":_vm.$t('choose-a-destination'),"icon":"v-location","icon-pos":"left","type":"list","list":_vm.autocomplete.data,"front-filter-list":false,"list-loading":_vm.autocomplete.loading,"form":_vm.form,"required":required,"errors":errors,"show-list-when-focus":true,"error-text-rules":"no-errors","list-serializer":_vm.highlightInputInList,"show-list-stop":_vm.$store.state.breakpoint.screen.widthMinCalc <= 767,"focus-after-clear":_vm.$store.state.breakpoint.screen.widthMinCalc > 767,"set-from-list-on-blur":_vm.setFromListOnBlur,"clear-icon":"","adaptive-list":{
                classes: ['search-bar-input']
              }},on:{"input":_vm.search,"focus":_vm.search,"listItemSelected":function (data) { return _vm.input = data; }},scopedSlots:_vm._u([{key:"list",fn:function(ref){
              var getFilteredList = ref.getFilteredList;
              var setFromList = ref.setFromList;
              var isShow = ref.isShow;
return [(isShow)?[_vm._l((getFilteredList),function(item,idx){return [_c('div',{key:((item.url) + "-" + idx),staticClass:"v-input-list__item",class:{
                        'title': ['popular'].includes(item.type),
                        'no-found': ['no-found'].includes(item.type),
                      },attrs:{"data-id":item.id,"data-value":item.value},on:{"click":function($event){return setFromList({id: item.id, name: item.raw, url: item.slug})}}},[(['no-found'].includes(item.type))?[_c('v-icon',{attrs:{"name":"v2-search"}}),_vm._v(" "+_vm._s(_vm.$t('no-matches-were-found'))+" ")]:(['popular'].includes(item.type))?[_vm._v(" "+_vm._s(_vm.$t('most-popular-destinations'))+" ")]:[_c('div',{staticClass:"icon"},[(_vm.getCodeByItem(item))?_c('v-img',{attrs:{"is-lazy-load":false,"src":("/flags/" + (_vm.getCodeByItem(item).toLowerCase()) + ".svg")}}):(_vm.getIconByLocationType(item.type))?_c('v-icon',{attrs:{"name":_vm.getIconByLocationType(item.type)}}):_vm._e()],1),_c('div',{staticClass:"name"},[_c('span',{staticClass:"title",class:item.type,domProps:{"innerHTML":_vm._s(item.highlight || item.name)}}),_c('span',{staticClass:"count"},[_vm._v(" ("+_vm._s(_vm._f("numberWithCommas")(item.boat_count))+")")])]),_c('div',{staticClass:"type"},[_vm._v(" "+_vm._s(_vm.$t(item.type))+" ")])]],2)]})]:_vm._e()]}}],null,true),model:{value:(_vm.rawInput),callback:function ($$v) {_vm.rawInput=$$v},expression:"rawInput"}})]}}],null,true)}),_c('validation-provider',{staticClass:"date  search-bar-input search-bar-input--date",attrs:{"rules":_vm.formRules.date,"name":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var required = ref.required;
return [_c('v-date',{attrs:{"name":"date","title":_vm.$t('select-date-title'),"placeholder":_vm.$t('select-date'),"clearable":true,"icon":"select-arrow","icon-pos":"right","start-date":_vm.date.start,"end-date":_vm.date.end,"min-date":_vm.date.min,"max-date":_vm.date.max,"disabled-dates":_vm.disabledBeforeToday,"form":_vm.form,"required":required,"errors":errors,"error-text-rules":"no-errors","range":"","dp-popup-classes":{'dp-search-bar': true}},on:{"close":_vm.onCloseDate,"onChangeCallback":_vm.onChangeCallback},scopedSlots:_vm._u([{key:"dp-header",fn:function(){return [_c('div',[_c('v-icon',{attrs:{"name":"fa-info-circle"}}),_vm._v(" "+_vm._s(_vm.$t('saturdays-offer-best-prices'))+" ")],1)]},proxy:true}],null,true),model:{value:(_vm.dateModel),callback:function ($$v) {_vm.dateModel=$$v},expression:"dateModel"}})]}}],null,true)}),_c('validation-provider',{staticClass:"boat_type  search-bar-input search-bar-input--boat_type",attrs:{"rules":_vm.formRules.boat_type,"name":"boat_type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var required = ref.required;
return [_c('v-select',{ref:"types",staticClass:"boat_type list-with-icons",attrs:{"name":"boat_type","title":_vm.$t('select-type-of-boat'),"placeholder":_vm.$t('any-types'),"options":_vm.boatTypes,"label-params":['name'],"icon":_vm.getBoatTypeIcon,"icon-fallback":"bt-catamaran","icon-pos":"left","adaptive-list":{
                classes: 'search-bar-input'
              },"form":_vm.form,"required":required,"errors":errors,"error-text-rules":"no-errors"},scopedSlots:_vm._u([{key:"list",fn:function(ref){
              var getPlaceholder = ref.getPlaceholder;
              var selectPl = ref.selectPl;
              var setActive = ref.setActive;
              var options = ref.options;
              var getLink = ref.getLink;
              var itemType = ref.itemType;
              var labelParams = ref.labelParams;
              var param = ref.param;
return [_vm._l((getPlaceholder),function(option,i){return (selectPl)?_c('li',{key:("select_pl_" + i),staticClass:"v-select__placeholder",on:{"click":function($event){$event.stopPropagation();return setActive(option)}}},[_vm._v(" "+_vm._s(option.name)+" ")]):_vm._e()}),_vm._l((options),function(option,i){return _c('li',{key:("select_" + i),class:itemType,on:{"click":function($event){$event.stopPropagation();return setActive(option)}}},[_c('v-icon',{attrs:{"name":option.icon,"fallback":"bt-catamaran"}}),_c('span',[_vm._v(" "+_vm._s(!labelParams.length ? option.name : labelParams.reduce(function (option, param) {return option[param]}, option))+" ")])],1)})]}}],null,true),model:{value:(_vm.typeModel),callback:function ($$v) {_vm.typeModel=$$v},expression:"typeModel"}})]}}],null,true)})],1),_c('div',{staticClass:"form__footer",class:{'br-up--sm': _vm.type === 'search' && !['destination', 'destination-location', 'boat-type', 'brand', 'sailing-holidays', 'sailing-with-skipper'].includes(_vm.$route.name)}},[_c('v-button',{staticClass:"button--tn-main",attrs:{"loading":_vm.busy.form,"text":_vm.$t('search'),"type":"button","lock-if-loading":""},on:{"click":_vm.submit}})],1)]:_vm._e()],2)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }