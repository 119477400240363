//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VCarousel from '~/components/global/v/carousel'

export default {
  name: 'section.vue',
  components: { VCarousel },
  props: {
    slider: {
      type: Object,
      default: () => {}
    },
    container: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    componentsIsSlider: app => (name) => {
      if (!app.slider) {
        return false
      }
      if (!app.slider[name]) {
        return false
      }
      if (app.slider[name] && app.slider[name].isSlider) {
        return app.slider[name]
      }
    },
    getSliderSettings: app => (name) => {
      return app.componentsIsSlider(name)?.settings
    }
  }
}
