//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18nVars from '~/mixins/i18nVars'

export default {
  name: 'BnrFlotilla',
  mixins: [i18nVars],
  props: {
    lang: {
      type: String,
      default: 'en'
    }
  }
}
