export default {
  computed: {
    isI18nVariable: app => (key = '') => {
      const value = app.$t(key, app.$store.getters['lang/locale'])
      if (value === '-') {
        return false
      }
      if (value === '+') {
        return true
      }
      return false
    },
    getI18nLink: app => (key = '') => {
      const value = app.$t(key, app.$store.getters['lang/locale'])
      if (value !== '-') {
        return value
      }
    }
  }
}
