//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import vRating from '~/components/v/rating.vue'
import SearchBarForm from '~/components/form/search-bar-form'
import settings from '~/mixins/settings'

export default {
  name: 'MainVue',
  components: {
    SearchBarForm,
    vRating
  },
  mixins: [settings],
  computed: {
    ...mapGetters({
      currentLanguage: 'lang/locale'
    })
  }
}
