import { render, staticRenderFns } from "./search-bar-form.vue?vue&type=template&id=a2f2d79c&scoped=true"
import script from "./search-bar-form.vue?vue&type=script&lang=js"
export * from "./search-bar-form.vue?vue&type=script&lang=js"
import style0 from "./search-bar-form.vue?vue&type=style&index=0&id=a2f2d79c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2f2d79c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/var/www/tubber-new/components/global/v/icon.vue').default,VImg: require('/var/www/tubber-new/components/global/v/img.vue').default,VInput: require('/var/www/tubber-new/components/global/v/input.vue').default,VDate: require('/var/www/tubber-new/components/global/v/date.vue').default,VSelect: require('/var/www/tubber-new/components/global/v/select.vue').default,VButton: require('/var/www/tubber-new/components/global/v/button.vue').default})
