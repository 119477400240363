//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dayjs from 'dayjs'
import sectionIndex from '~/components/section/index/section'
import { capitalizeFirstLetter } from '~/utils/utils'

export default {
  name: 'RecentSearches',
  components: {
    sectionIndex
  },
  filters: {
    formatDate (date) {
      return dayjs(date).format('DD/MM/YYYY')
    }
  },
  props: {
    slider: {
      type: Object,
      default: () => {}
    },
    searches: {
      type: Array,
      default: () => []
    },
    container: {
      type: Boolean,
      default: true
    },
    isLogged: {
      type: Object,
      default: () => null
    },
    lang: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    boatTypes () {
      return this.$store.state.search.staticBoatTypes.map((t) => {
        return {
          ...t,
          name: t.translate ? capitalizeFirstLetter(this.$t(t.name.toLowerCase())) : t.name
        }
      })
    }
  },
  methods: {
    getBoatTypeTitle (id) {
      return this.boatTypes?.find(t => t.id === id)?.name || this.$t('any-types')
    }
  }
}
