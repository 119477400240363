import { render, staticRenderFns } from "./rating.vue?vue&type=template&id=78c2a30a&scoped=true"
import script from "./rating.vue?vue&type=script&lang=js"
export * from "./rating.vue?vue&type=script&lang=js"
import style0 from "./rating.vue?vue&type=style&index=0&id=78c2a30a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78c2a30a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/var/www/tubber-new/components/global/v/icon.vue').default})
