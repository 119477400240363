//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'vform'
import { mapGetters } from 'vuex'
import dayjs from '~/modules/dayjs'
import veeValidate from '~/mixins/veeValidate'
import { capitalizeFirstLetter, exists } from '~/utils/utils'
import searchInput from '~/mixins/search/search-input'
import { debounce, isObject } from '~/utils/v'

export default {
  name: 'SearchBarForm',
  mixins: [veeValidate, searchInput],
  props: {
    term: {
      type: Array,
      default () {
        return []
      }
    },
    showErrorState: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'default'
    },
    boatTypeRequired: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      busy: {
        form: false
      },
      cleared: {
        date: false
      },
      form: new Form({
        location: '',
        date: null,
        boat_type: ''
      })
    }
  },
  methods: {
    onChangeCallback (ev) {
      if (!ev[0]) {
        this.form.date = null
        this.$store.commit('search/SET_BAR', { condition: 'current', data: { date: null } })
        this.$store.commit('search/SET_DATE', null)
      }
    },
    inputClickHandler (e) {
      this.$modal.show('search-destination-input-modal')
    },
    setClearedDate () {
      this.cleared.date = true
    },
    disabledBeforeToday (date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0))
    },
    search: debounce(async function (e) {
      if (
        this.location?.name === this.$store.state.search.bar.current.rawLocation ||
        !this.rawInput ||
        this.rawInput === this.$store.state.search?.autocomplete?.value
      ) {
        this.$store.commit('search/SET_AUTOCOMPLETE', { data: [], loading: false })
        return
      }
      await this.$store.dispatch('search/getAutocomplete', this.rawInput)
    }, 300),
    async submit () {
      const storedFilters = [
        'cabins',
        'guestRange',
        'licenseRequired',
        'sortBy'
      ]
      let query = this.$route.query
      const filteredQuery = {}
      storedFilters.forEach((filter) => {
        if (query.hasOwnProperty(filter)) {
          filteredQuery[filter] = query[filter]
        }
      })
      query = filteredQuery

      this.busy.form = true
      let boat_type = null
      let boat_type_id = null

      if (!this.location?.url || this.location.name !== this.$store.state.search.bar.current.rawLocation) {
        this.form.errors.set('location', '')
        if (this.$refs?.location?.$el) {
          if (this.$refs.location?.$el?.previousSibling && this.$refs.location?.$el?.previousSibling?.classList?.contains('prevent-only-mobile') &&
            window.getComputedStyle(this.$refs.location?.$el?.previousSibling).display !== 'none'
          ) {
            this.inputClickHandler()
          } else {
            this.$refs.location.setFocus()
          }
        }
        this.busy.form = false
        return
      }

      if (this.boatTypeRequired && !this.form.boat_type) {
        this.form.errors.set('boat_type', '')
        if (this.$refs?.types?.$el) {
          this.$refs.types.openList()
        }
        this.busy.form = false
        return
      }

      this.form.boat_type = this.typeModel
      if (this.form.boat_type && this.boatTypes.find(bt => bt.id === this.form.boat_type)) {
        boat_type = this.boatTypes.find(bt => bt.id === this.form.boat_type)?.slug || null
        boat_type_id = this.form.boat_type
      }
      let routeName = 'search'
      const params = {}

      params.location = this.location.url

      if (exists(boat_type_id) && boat_type_id !== 9999) {
        if (exists(boat_type)) {
          routeName = 'search_with_boat'
          params.boatType = boat_type
        }

        query.boat_type_id = boat_type_id
      }
      if (this.$route.params.charterType) {
        routeName = 'search_with_boat_charter'
      }

      if (this.form.date && this.form.date[0] && !isNaN(this.form.date[0].getMonth())) {
        let m = this.form.date[0].getMonth() + 1
        m = m < 10 ? '0' + m : m
        let d = this.form.date[0].getDate()
        d = d < 10 ? '0' + d : d

        query.start_date = this.form.date[0].getFullYear() + '-' + m + '-' + d
        query.duration = this.duration !== undefined ? this.duration : 0
        this.$store.commit('SET_SIMPLE_SEARCH', false)
      } else {
        this.$store.commit('SET_SIMPLE_SEARCH', true)
      }

      if (this.$route.params?.location !== this.location.url) {
        await this.$vFilter().RESETALL()
        await this.$store.dispatch('search/clearSearch')
      }

      await this.$router.push({
        name: routeName,
        params,
        query
      }).catch((e) => {
        console.log(e)
      })
      // await this.$search.applyFilters(this, query)
      this.submitted = false
      this.busy.form = false
    },
    onCloseDate () {
      this.cleared.date = false
    },
    getPreviousParams () {
      this.input = this.$store.getters['search/bar'].prev.location
      this.typeModel = this.$store.getters['search/bar'].prev.boat_type
      this.dateModel = this.$store.getters['search/bar'].prev.date
    },
    setPreviousParams () {
      const charterTypeFromUrl = this.$store.getters['data/chartered'].find(bt => bt.transTitle === this.$route.params.charterType)
      const dt = {
        boat_type: this.typeModel,
        location: this.input,
        date: this.dateModel
      }
      if (charterTypeFromUrl) {
        dt.charter_type = charterTypeFromUrl.transTitle
        dt.charter_type_id = charterTypeFromUrl.id
      }
      this.$store.commit('search/SET_BAR', {
        condition: 'prev',
        data: dt
      })
    }
  },
  computed: {
    ...mapGetters({
      bar: 'search/bar',
      locale: 'lang/locale',
      currentCurrency: 'lang/currentCurrency',
      boatFormData: 'data/boatFormData',
      duration: 'search/duration',
      searchTerms: 'search/searchTerms',
      autocomplete: 'search/autocomplete',
      location: 'search/location'
    }),
    formRules () {
      return {
        location: 'required',
        date: 'required',
        boat_type: this.boatTypeRequired ? 'required' : ''
      }
    },
    getBoatTypeIcon () {
      const result = this.boatTypes.find(bt => bt.id === this.typeModel)?.icon
      return result || 'bt-any'
    },
    date: {
      get () {
        const end = new Date(this.$store.state.search.date + 'T00:00:00')
        end.setDate(end.getDate() + this.$store.state.search.duration)
        return {
          start: new Date(this.$store.state.search.date + 'T00:00:00'),
          end,
          min: null,
          max: null
        }
      },
      set (value) {

      }
    },
    getBoatTypeTitle () {
      return this.boatTypes?.find(t => t.id === this.bar.current.boat_type)?.name || this.$t('any-types')
    },
    boatTypes () {
      return this.$store.state.search.staticBoatTypes.map((t) => {
        return {
          ...t,
          name: t.translate ? capitalizeFirstLetter(this.$t(t.name.toLowerCase())) : t.name
        }
      })
    },
    rawInput: {
      get () {
        const value = this.$store.state.search.bar.current.rawLocation
        return exists(value?.name) ? value?.name : value
      },
      set (value) {
        if (isObject(value) && 'name' in value) {
          value = value.name
        }
        this.form.errors.clear('location')
        this.$store.commit('search/SET_BAR', {
          condition: 'current',
          data: {
            rawLocation: value
          }
        })
      }
    },
    input: {
      get () {
        if (this.$store.state.search.location) {
          const result = {
            ...this.$store.state.search.location,
            value: this.$store.state.search.location.name
          }
          if (!this.$store.state.search.location.id) {
            result.id = 1
          }
          return result
        }
        return ''
      },
      set (value) {
        value = value || ''
        this.$store.commit('search/SET_LOCATION', { name: exists(value.name) ? value.name : value })
        if (!value) {
          this.$store.commit('search/SET_LOCATION', null)
        }
        if (value.url) {
          this.$store.commit('search/SET_BAR', {
            condition: 'current', data: { location: { ...value, value: value.name } }
          })
          this.$store.commit('search/SET_LOCATION', { ...value, value: value.name })
          this.$store.commit('search/SET_AUTOCOMPLETE', { data: [] })
        }
      }
    },
    dateModel: {
      get () {
        if (this.cleared.date) {
          return []
        }
        if (process.client) {
          const end = new Date(this.$store.state.search.date + 'T00:00:00')
          end.setDate(end.getDate() + this.$store.state.search.duration)
          return [
            new Date(this.$store.state.search.date + 'T00:00:00'),
            end
          ]
        } else {
          const end = new Date(this.$store.state.search.date)
          end.setDate(end.getDate() + this.$store.state.search.duration)
          return [
            new Date(this.$store.state.search.date),
            end
          ]
        }
      },
      set (value) {
        if (value && value[0] && !isNaN(value[0].getMonth())) {
          let m = value[0].getMonth() + 1
          m = m < 10 ? '0' + m : m
          let d = value[0].getDate()
          d = d < 10 ? '0' + d : d
          const dt = value[0].getFullYear() + '-' + m + '-' + d
          this.form.date = value
          this.$store.commit('search/SET_BAR', { condition: 'current', data: { date: value } })
          this.$store.commit('search/SET_DURATION', dayjs(value[1]).diff(dayjs(value[0]), 'day'))
          this.$store.commit('search/SET_DATE', dt)
        }
      }
    },
    typeModel: {
      get () {
        if (this.bar.current.boat_type) {
          return this.bar.current.boat_type
        }
        if (this.searchTerms.boat_type || this.searchTerms.boat_type_id || this.searchTerms.location) {
          if ((this.$route.name === 'search_with_boat' || this.$route.name === 'search_with_boat_charter') && this.$route.params.boatType) {
            const boatTypeFromUrl = this.boatTypes.find(bt => bt.slug === this.$route.params.boatType)
            const charterTypeFromUrl = this.$store.getters['data/chartered'].find(bt => bt.transTitle === this.$route.params.charterType)
            if (boatTypeFromUrl) {
              const dt = { boat_type: boatTypeFromUrl.id }
              if (charterTypeFromUrl) {
                dt.charter_type = charterTypeFromUrl.transTitle
                dt.charter_type_id = charterTypeFromUrl.id
              }
              this.$store.commit('search/SET_BAR', { condition: 'current', data: dt })
              return boatTypeFromUrl.id
            }
          }
        }
        return 9999
      },
      set (value) {
        const charterTypeFromUrl = this.$store.getters['data/chartered'].find(bt => bt.transTitle === this.$route.params.charterType)
        const dt = { boat_type: value }
        if (charterTypeFromUrl) {
          dt.charter_type = charterTypeFromUrl.transTitle
          dt.charter_type_id = charterTypeFromUrl.id
        }
        this.$store.commit('search/SET_BAR', { condition: 'current', data: dt })
        this.$store.commit('search/SET_BOAT_TYPE', value)
        this.form.boat_type = value
      }
    },
    getCurrentDate: app => (parsed = false) => {
      function formatDate (date) {
        const d = new Date(date)
        let month = '' + (d.getMonth() + 1)
        let day = '' + d.getDate()
        const year = d.getFullYear()

        if (month.length < 2) {
          month = '0' + month
        }
        if (day.length < 2) {
          day = '0' + day
        }

        return [day, month, year].join('/')
      }

      if (parsed && Array.isArray(app.dateModel) && app.dateModel.length === 2) {
        if (process.server) {
          return ([
            formatDate(app.dateModel[0]),
            formatDate(app.dateModel[1])
          ])
        }
        return [
          app.$options.filters.formattedDate(app.dateModel[0], 'YYYY-MM-DD', 'DD/MM/YYYY'),
          app.$options.filters.formattedDate(app.dateModel[1], 'YYYY-MM-DD', 'DD/MM/YYYY')
        ]
      }
      if (parsed) {
        return app.$options.filters.formattedDate(app.dateModel, 'YYYY-MM-DD', 'DD/MM/YYYY')
      }
      return app.dateModel
    }
  },
  async created () {
    if (this.input && this.input.name) {
      this.rawInput = this.input
    }
    this.setPreviousParams()

    this.$nuxt.$on('search/updateLocationInput', () => {
      this.rawInput = this.input
    })

    this.$store.dispatch('search/getAutocompletePopular')
  }
}
