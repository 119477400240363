import https from 'https'
import axios from 'axios'
import { findLang } from '~/utils/i18n'

const aProxy = axios.create({
  headers: {
    common: {
      Accept: 'text/plain, */*'
    }
  },
  baseURL: process.env.apolloUrl,
  timeout: 60000
})

if (['local', 'localProd'].includes(process.env.MODE)) {
  aProxy.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false })
}

export function asyncDataCatch (ctx, e) {
  console.log(e)
  console.log('Route name: ' + ctx?.$route?.name)
  if (e?.response?.status >= 500) {
    return ctx.error({ statusCode: 500, message: 'Data loading error', data: e })
  }
  if ([404].includes(e?.response?.status)) {
    return ctx.error({ statusCode: 404, message: 'Page not found' })
  }
  return ctx.error({ statusCode: 500, message: 'Render error', data: e })
}

export async function asyncDataStart (ctx) {
  if (ctx.i18n.locale !== ctx.route.params.lang) {
    const routeLocale = ctx.route.params.lang
    const lang = findLang({ i18n: ctx.i18n, store: ctx.store }, routeLocale)
    if (lang.code) {
      ctx.i18n.setLocale(lang.code)
      await ctx.store.dispatch('lang/setLocale', { locale: lang.code })
    }
  }
}
