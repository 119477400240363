export const Breadcrumbs = {
  get (th, pageType, route, data) {
    const crumbs = []
    let key = '$t'
    if (!th[key]) {
      key = 't'
    }
    crumbs.push({
      title: th[key]('home'),
      url: `/${route?.params?.lang}/` || '/en/'
    })
    switch (pageType) {
      case 'page':
        if (Array.isArray(data)) {
          data.forEach((crumb) => {
            crumbs.push({
              title: crumb.title,
              url: `${crumb.url.charAt(0) !== '/' ? '/' : ''}${crumb.url}`
            })
          })
        } else {
          crumbs.push({
            title: data.title,
            url: `${data.url.charAt(0) !== '/' ? '/' : ''}${data.url}`
          })
        }
        break
      default:
        break
    }
    return crumbs
  }
}
