var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('client-only',[_c('div',{ref:"v-carousel",staticClass:"v-carousel",class:{'dots': _vm.getCurrentSettings.dots},style:({'--slider-gap': _vm.getCurrentSettings.gap, '--slide-width': (_vm.getSlideWidth + "px"),
             '--cur-start': _vm.getCurrentSlide,
             '--cur-end': _vm.getCurrentSlide + _vm.getCountToShow -1,
    })},[_c('div',{ref:"container",staticClass:"v-carousel__container"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.showControls && _vm.getCurrentSettings.arrows)?_c('v-button',{staticClass:"v-carousel-button v-carousel-button--prev button--tn-outline--circle",class:{'disabled': _vm.isFirstSlide},attrs:{"btn-type":"icon","icon-classes":"left","icon":"arrow-down","fit-content":""},nativeOn:{"click":function($event){return _vm.goToSlide('prev')}}}):_vm._e()],1),_c('div',{ref:"carousel",staticClass:"v-carousel__carousel"},[_c('div',{ref:"track",staticClass:"v-carousel__track",class:{loaded: _vm.carousel.loaded, 'only-one': _vm.getCountToShow === 1},on:{"scroll":_vm.setCurrentTrackPosition}},[_vm._t("default")],2)]),_c('transition',{attrs:{"name":"fade"}},[(_vm.showControls && _vm.getCurrentSettings.arrows)?_c('v-button',{staticClass:"v-carousel-button v-carousel-button--next button--tn-outline--circle",class:{'disabled': _vm.isLastSlide},attrs:{"btn-type":"icon","icon-classes":"right","icon":"arrow-down","fit-content":""},nativeOn:{"click":function($event){return _vm.goToSlide('next')}}}):_vm._e()],1),(_vm.showControls && _vm.getCurrentSettings.dots)?_c('div',{ref:"dots",staticClass:"v-carousel-dots",class:[("slides-to-show--" + _vm.getCountToShow), {'only-last': _vm.isLastSlide}],attrs:{"role":"tablist"}},[_vm._l((Math.ceil(_vm.getSlidesCount)),function(i){return [(i === 1 || (i - 1) % _vm.getCountToShow === 0)?_c('div',{staticClass:"v-carousel-dots__dot",class:{
              active:
                (i === _vm.getCurrentSlide && !_vm.isLastSlide)
                || (_vm.isLastSlide && i === _vm.getSlidesCount- (_vm.getCountToShow - 1))
                || _vm.betweenNumbers(_vm.getCurrentSlide, i, i + _vm.getCountToShow)
            },on:{"click":function($event){return _vm.goToSlide(i)}}}):_vm._e()]})],2):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }