//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'RecommendedLocations',
  props: {
    locations: {
      type: Object,
      default: () => {}
    },
    lang: {
      type: String,
      default: 'en'
    }
  },
  data () {
    return {
      selectedFilter: 'countries'
    }
  }
}
