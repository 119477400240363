//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import computedDimensions from '~/mixins/vue-computed-dimensions'
import { mergeDeep } from '~/utils/utils'

const defaultSettings = {
  arrows: true,
  dots: true,
  infinite: false,
  gap: '24px',
  slidesToShow: 3,
  slidesToScroll: 1,
  scrollBehavior: 'smooth'
}

export default {
  name: 'VCarousel',
  mixins: [computedDimensions('container', 'carousel', 'track')],
  props: {
    settings: {
      type: Object,
      default: () => {
        return {
          ...defaultSettings
        }
      }
    }
  },
  data () {
    return {
      carousel: {
        loaded: false,
        slideWidth: 0,
        currentBreakpoint: 0,
        slidesCount: null,
        observer: {
          resize: null,
          mutation: null
        }
      },
      getCurrentTrackPosition: 0
    }
  },
  computed: {
    isFirstSlide () {
      return this.getCurrentTrackPosition <= 0
    },
    isLastSlide () {
      return this.getCurrentTrackPosition >= this.getSlidesCount * this.getFullSlideWidth - (this.getFullSlideWidth * this.getCountToShow)
    },
    getCountToShow () {
      if (!this.carousel.loaded) {
        return
      }
      if (!this.$refs.track || !this.dimensions$.containerWidth || !this.dimensions$.trackWidth) {
        return
      }
      return Math.round((this.dimensions$.containerWidth + this.getSliderGap) / this.getFullSlideWidth)
    },
    showControls () {
      return this.dimensions$.containerWidth < (this.getSlideWidth * this.getSlidesCount) + 1 + this.getSliderGap / 2
    },
    getSlidesCount: {
      get () {
        if (this.carousel.slidesCount) {
          return this.carousel.slidesCount
        }
        if (this.$slots?.default?.length) {
          return this.$slots.default.filter(c => !!c.tag).length || 0
        }
        return 0
      },
      set (value) {
        this.carousel.slidesCount = value
      }
    },
    getFullSlideWidth () {
      return this.carousel.slideWidth + this.getSliderGap
    },
    getSlideWidth () {
      if (!this.carousel.loaded) {
        return
      }
      return parseFloat(getComputedStyle(this.$refs['v-carousel'])?.getPropertyValue('--slide-width')?.replace('px', ''))
    },
    getCalcSlideWidth () {
      return ((this.dimensions$.containerWidth - (this.getSliderGap * (this.getCurrentSettings.slidesToShow - 1))) / this.getCurrentSettings.slidesToShow) + this.getSliderGap / 2
    },
    getSliderGap () {
      if (!this.carousel.loaded) {
        return
      }
      return parseFloat(getComputedStyle(this.$refs['v-carousel'])?.getPropertyValue('--slider-gap')?.replace('px', ''))
    },
    getSettings () {
      return mergeDeep({ ...defaultSettings }, this.settings)
    },
    getCurrentSlide () {
      if (!this.carousel.loaded || !this.carousel.slideWidth) {
        this.$emit('changeSlide', 1)
        return 1
      }
      if (!this.getCurrentTrackPosition) {
        this.$emit('changeSlide', 1)
        return 1
      }
      const result = Math.round((this.getCurrentTrackPosition / (this.carousel.slideWidth + this.getSliderGap) / this.getSettings.slidesToScroll + 1)) || 1
      this.$emit('changeSlide', result)
      return result
    },
    getCurrentSettings () {
      if (!this.getSettings?.responsive) {
        return this.getSettings
      }

      const curWidth = this.$store.state.breakpoint.screen.widthMinCalc
      const sortedSettings = [...this.getSettings.responsive].sort((a, b) => {
        if (a.breakpoint < b.breakpoint) {
          return -1
        }
        if (a.breakpoint > b.breakpoint) {
          return 1
        }
        return 0
      })
      let currentSettings = sortedSettings.find((r) => {
        if (r.breakpoint >= curWidth) {
          return true
        }
        return false
      }) || null

      if (!currentSettings && sortedSettings[sortedSettings.length - 1]) {
        currentSettings = sortedSettings[sortedSettings.length - 1]
      }

      if (!currentSettings) {
        currentSettings = {}
        currentSettings.settings = this.getSettings
        currentSettings.breakpoint = 0
      }

      this.carousel.currentBreakpoint = currentSettings.breakpoint
      return mergeDeep(this.getSettings, currentSettings.settings)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.carousel.observer.resize = new ResizeObserver((entries) => {
        this.$refs['v-carousel'].style.cssText += `--slide-width: ${this.getCalcSlideWidth}px;`
        this.$nextTick(() => {
          if (entries[0].borderBoxSize) {
            if (entries[0].borderBoxSize[0]?.inlineSize) {
              this.carousel.slideWidth = entries[0].borderBoxSize[0]?.inlineSize
            }
          } else if (entries[0].contentRect) {
            if (entries[0].contentRect.width) {
              this.carousel.slideWidth = entries[0].contentRect.width
            }
          }
        })
      })

      this.carousel.observer.mutation = new MutationObserver((mutationList, observer) => {
        if (this.$refs?.track) {
          this.getSlidesCount = this.$refs.track.children.length
        }

        this.$refs['v-carousel'].style.cssText += `--slide-width: ${this.getCalcSlideWidth}px;`
        if (mutationList[0]?.addedNodes[0]?.clientWidth) {
          setTimeout(() => {
            this.carousel.slideWidth = mutationList[0].addedNodes[0].clientWidth
          }, 500)
        }
      })

      this.$nextTick(() => {
        this.carousel.observer.mutation.observe(this.$refs.track, {
          subtree: false,
          childList: true,
          // it's better if you can detect children change by id
          // this can reduce number of updates
          // attributes: true,
          // attributeList: [ 'id' ]
        })

        if (this.$refs.track && this.$slots?.default?.length) {
          this.carousel.loaded = true
          this.carousel.observer.resize.observe(this.$refs.track.querySelector(':first-child'))
        }
        if (this.$refs['v-carousel']) {
          this.$refs['v-carousel'].style.cssText += `--slide-width: ${this.getCalcSlideWidth}px;`
        }
      })
    })
  },
  beforeDestroy () {
    if (this.carousel.observer.resize) {
      this.carousel.observer.resize.disconnect()
    }
    if (this.carousel.observer.mutation) {
      this.carousel.observer.mutation.disconnect()
    }
  },
  methods: {
    betweenNumbers (value, a, b) {
      const min = Math.min.apply(Math, [a, b])
      const max = Math.max.apply(Math, [a, b])
      return value > min && value < max
    },
    setCurrentTrackPosition (value) {
      this.getCurrentTrackPosition = value.srcElement.scrollLeft
    },
    goToSlide (toSlide) {
      let scrollMethod = 'scrollBy'
      let delta = 0

      switch (toSlide) {
        case 'prev':
          delta = Math.abs((this.carousel.slideWidth + this.getSliderGap) * this.getSettings.slidesToScroll) * -1
          break
        case 'next':
          if (this.isLastSlide && this.getCurrentSettings.infinite) {
            scrollMethod = 'scrollTo'
            delta = 0
          } else {
            delta = (this.carousel.slideWidth + this.getSliderGap) * this.getSettings.slidesToScroll
          }
          break
        default:
          delta = (this.carousel.slideWidth + this.getSliderGap) * (parseInt(toSlide) - 1)
          scrollMethod = 'scrollTo'
          break
      }

      if (scrollMethod === 'scrollBy') {
        const scroll = {
          left: delta,
          behavior: this.getSettings.scrollBehavior
        }
        this.$refs.track[scrollMethod](scroll)
        // if (toSlide === 'next' && this.getCurrentSlide === this.getSlidesCount - 1){
        //   this.getCurrentTrackPosition = delta + delta
        // }
      } else {
        const scroll = {
          left: delta,
          behavior: this.getSettings.scrollBehavior
        }
        this.$refs.track[scrollMethod](scroll)
        // this.getCurrentTrackPosition = delta
      }
    }
  }
}
