//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { lowerCase } from '~/utils/utils'
import BlueDeal from '~/components/section/banners/blueDeal'
import FreeCancellation from '~/components/section/banners/freeCancellation'
import TopFlotilla from '~/components/section/banners/topFlotilla'

export default {
  name: 'TheMostPopularHome',
  components: {
    TopFlotilla,
    FreeCancellation,
    BlueDeal
  },
  props: {
    slider: {
      type: Object,
      default: () => {}
    },
    locations: {
      type: Array,
      default: () => []
    },
    container: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getLink (location) {
      switch (this.$route.name) {
        case 'boat-type':
          return {
            name: 'search_with_boat',
            params: { location: location.slug, boatType: this.$store.state.page['boat-type'].boatType.slug }
          }
        case 'brand':
          return {
            name: 'search', params: { location: location.slug }, query: { brand: this.$store.state.page.brand.name }
          }
        default:
          return { name: 'destination-location', params: { location: location.slug } }
      }
    },
    getBoatCount (location) {
      let count = this.$options.filters.numberWithCommas(location.boat_count)

      if (['boat-type'].includes(this.$route.name) && Array.isArray(location.boat_types)) {
        const boatTypeId = this.$store?.getters['page/boatTypePage']?.boatType?.id
        if (boatTypeId) {
          const boatType = location?.boat_types.find(i => i.id == boatTypeId)
          if (boatType) {
            count = this.$options.filters.numberWithCommas(boatType.boat_count)
            return `${count} ${lowerCase(this.$store.getters['page/boatTypePage']?.boatType?.plural_name) || this.$t('boats_low')}`
          }
        }
      }

      return `${count} ${this.$t('boats_low')}`
    }
  }
}
