import { render, staticRenderFns } from "./offers-in-mailbox.vue?vue&type=template&id=57ab6be4&scoped=true"
import script from "./offers-in-mailbox.vue?vue&type=script&lang=js"
export * from "./offers-in-mailbox.vue?vue&type=script&lang=js"
import style0 from "./offers-in-mailbox.vue?vue&type=style&index=0&id=57ab6be4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57ab6be4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VInput: require('/var/www/tubber-new/components/global/v/input.vue').default,VButton: require('/var/www/tubber-new/components/global/v/button.vue').default})
