//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18nVars from '~/mixins/i18nVars'

export default {
  name: 'BnrSpring',
  mixins: [i18nVars],
  props: {
    bannerType: {
      type: String,
      default: ''
    },
    isLink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showBanner () {
      return this.isI18nVariable('--is-banner--spring') ||
        (this.bannerType === 'black-friday' || this.bannerType === 'black-friday-small') ||
        (this.bannerType === 'christmas' || this.bannerType === 'christmas-small') ||
        (this.bannerType === 'valentine' || this.bannerType === 'valentine-small') ||
        (this.bannerType === 'january' || this.bannerType === 'january-small')
    }
  },
  methods: {
    navigate (link) {

        window.location.href = link

    }
  }
}
