//
//
//
//
//
//
//
//
//
//
//
//

import i18nVars from '~/mixins/i18nVars'

export default {
  name: 'TopFlotilla',
  mixins: [i18nVars],
  props: {
    lang: {
      type: String,
      default: 'en'
    }
  },
  methods: {
    navigate (url) {
      if (url && url !== 'top-flotillas-link' && url !== '-') {
        window.location.href = url
      }
    }
  }
}
