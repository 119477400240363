//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VRating',
  props: {
    value: {
      type: Number,
      default: 0
    },
    colorDark: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: 5
    },
    minMode: {
      type: Boolean,
      default: false
    },
    icons: {
      type: Object,
      default: () => {
        return {
          base: { name: 'fa-star', src: '/assets/icons/fa/star-disabled.svg?inline' },
          active: { name: 'fa-star', src: '/assets/icons/fa/star.svg?inline' },
          half: { name: 'fa-star-half', src: '/assets/icons/fa/star-half.svg?inline' },
          half_gr: { name: 'fa-star-half-gr', src: '/assets/icons/fa/star-half-gr.svg?inline' }
        }
      }
    },
    reviewCount: {
      type: [Number, String],
      default: 1
    }
  },
  computed: {
    showStar: app => (item, idx) => {
      if (item === 0 && app.minMode) {
        return false
      }
      return true
    },
    ratingParts () {
      const value = Math.min(parseFloat(this.value, 10), this.max)
      const result = []
      for (let i = 0; i < Math.floor(value); i++) {
        result.push(1)
      }

      if (value % 1 !== 0) {
        result.push(value % 1)
      }

      while (result.length < this.max) {
        result.push(0)
      }

      return result
    }
  },
  jsonld () {
    if (!this.value) {
      return null
    }
    return {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: 'Tubber',
      aggregateRating: {
        '@type': 'AggregateRating',
        bestRating: this.max,
        worstRating: 0,
        ratingValue: this.value <= this.max ? this.value : this.max,
        reviewCount: this.reviewCount || 1
      }
    }
  }
}
