//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import sectionIndex from '~/components/section/index/section'
import BlueDeal from '~/components/section/banners/blueDeal'
import FreeCancellation from '~/components/section/banners/freeCancellation'
import TopFlotilla from '~/components/section/banners/topFlotilla'

export default {
  name: 'HomeBannersSlider',
  components: {
    sectionIndex,
    TopFlotilla,
    FreeCancellation,
    BlueDeal
  },
  props: {
    slider: {
      type: Object,
      default: () => {}
    },
    container: {
      type: Boolean,
      default: true
    }
  }
}
