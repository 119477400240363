//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DatePicker from 'vue2-datepicker'
import dayjs from '~/modules/dayjs'
import vInput from '~/mixins/vInput'
import { isWindow } from '~/plugins/v-js'
import { isObject } from '~/utils/v'

const getRangeClasses = DatePicker.CalendarRange.methods.getRangeClasses

function isValidDate (date) {
  return date instanceof Date && !isNaN(date)
}

function startOfYear (value) {
  const date = new Date(value)
  date.setMonth(0, 1)
  date.setHours(0, 0, 0, 0)
  return date
}

function startOfMonth (value) {
  const date = new Date(value)
  date.setDate(1)
  date.setHours(0, 0, 0, 0)
  return date
}

function startOfDay (value) {
  const date = new Date(value)
  date.setHours(0, 0, 0, 0)
  return date
}

function formatDate (date, format) {
  let m = date.getMonth() + 1
  m = m < 10 ? '0' + m : m
  let d = date.getDate()
  d = d < 10 ? '0' + d : d
  return format.replace('YYYY', date.getFullYear()).replace('MM', m).replace('DD', d)
}

const originalRenderInput = DatePicker.methods.renderInput
DatePicker.methods.renderInput = function () {
  const originalResult = originalRenderInput.apply(this, arguments)

  if (originalResult && originalResult.data && originalResult.data.class && originalResult.data.class.includes(`${this.prefixClass}-input-wrapper`)) {
    if (this.clearable) {
      originalResult.children.push(
        this.$createElement('i', {
          class: `${this.prefixClass}-icon-clear`,
          on: { click: this.handleClear }
        }, [this.renderSlot('icon-clear')])
      )
    }
    originalResult.children.push(
      this.$createElement('i', {
        class: `${this.prefixClass}-icon-calendar`
      }, [this.renderSlot('icon-calendar')])
    )

    return originalResult
  }

  return this.$createElement('div', {
    class: `${this.prefixClass}-input-wrapper`,
    on: {
      mouseenter: this.handleMouseEnter,
      mouseleave: this.handleMouseLeave,
      click: this.openPopup
    },
    ref: 'inputWrapper'
  }, [
    originalResult,
    this.clearable && this.$createElement('i', {
      class: `${this.prefixClass}-icon-clear`,
      on: { click: this.handleClear }
    }, [this.renderSlot('icon-clear')]),
    this.$createElement('i', {
      class: `${this.prefixClass}-icon-calendar`
    }, [this.renderSlot('icon-calendar')])
  ])
}

DatePicker.CalendarPanel.computed.innerValue = function (...args) {
  const value = Array.isArray(this.value) ? this.value : [this.value]
  const map = {
    year: startOfYear,
    month: startOfMonth,
    date: startOfDay
  }
  const start = map[this.type] || map.date
  const result = value.filter(isValidDate).map(function (v) {
    return start(v)
  })

  if (Array.isArray(result) && isValidDate(result[1])) {
    // Scroll for modal mode
    const el = document.querySelector('.mx-datepicker-body')
    if (el) {
      el.scrollTop = 0
    }
  }

  return result
}

DatePicker.CalendarRange.props.getClasses.default = function (cellDate = null, currentDates = null, classnames = '') {
  if (classnames) {
    return classnames.split(' ')
  }
  return []
}

DatePicker.props = {
  ...DatePicker.props,
  optionsDates: {}
}
DatePicker.CalendarRange.props = {
  ...DatePicker.CalendarRange.props,
  optionsDates: {}
}

DatePicker.CalendarRange.methods.getRangeClasses = function (...args) {
  const cellDate = args[0]
  const currentDates = args[1]
  let classnames = args[2]
  if (isObject(this.optionsDates)) {
    for (const [cls, dates] of Object.entries(this.optionsDates)) {
      if (dates.includes(formatDate(cellDate, 'YYYY-MM-DD'))) {
        classnames += ` ${cls}`
      }
    }
  }

  if (new Date(cellDate).getTime() === new Date(currentDates[0]).getTime()) {
    classnames += ' start-range'
  }
  try {
    if (currentDates[0] && currentDates[1]) {
      if (dayjs(cellDate) && dayjs(cellDate).isBetween(dayjs(currentDates[0]).toDate(), dayjs(currentDates[1]).toDate(), 'day')) {
        classnames += ' in-range-force'
      }
    }
  } catch (e) {
    console.log(e)
  }
  if (new Date(cellDate).getTime() === new Date(currentDates[1]).getTime()) {
    classnames += ' end-range'
  }
  return getRangeClasses.apply(this, [cellDate, currentDates, classnames])
}

DatePicker.CalendarRange.methods.updateCalendars = function (
  calendars,
  adjustIndex = 1
) {
  const [newStartDate, newEndDate] = calendars
  const [oldStartDate, oldEndDate] = this.calendars

  if (this.calendars.length === 0 || newStartDate !== oldStartDate) {
    const newStartDateMonth = newStartDate.getMonth()
    const newStartDateYear = newStartDate.getFullYear()
    const nextDate = new Date(newStartDateYear, newStartDateMonth + 1)

    this.calendars = [newStartDate, nextDate]
  } else if (newEndDate !== oldEndDate) {
    const newEndDateMonth = newEndDate.getMonth()
    const newEndDateYear = newEndDate.getFullYear()
    const previousDate = new Date(newEndDateYear, newEndDateMonth - 1)

    this.calendars = [previousDate, newEndDate]
  }
}

export default {
  name: 'Date',
  components: { DatePicker },
  mixins: [vInput],
  props: {
    ...vInput.props,
    range: {
      type: Boolean,
      default: false
    },
    disabledDates: {
      type: Function,
      default: () => true
    },
    minDate: {
      type: Date,
      default: () => new Date()
    },
    maxDate: {
      type: Date,
      default: () => dayjs().add(1, 'y').toDate()
    },
    startDate: {
      type: [Date],
      default: null
    },
    endDate: {
      type: [Date],
      default: null
    },
    checkInDate: {
      type: String,
      default: ''
    },
    checkOutDate: {
      type: String,
      default: ''
    },
    blockedDates: {
      type: Array,
      default: () => []
    },
    isFormRequest: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    withLegend: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    alwaysOpen: {
      type: Boolean,
      default: false
    },
    difference: {
      type: Number,
      default: 7
    },
    rentalType: {
      type: String,
      default: ''
    },
    dpClasses: {
      type: Object,
      default: () => {
      }
    },
    dpPopupClasses: {
      type: Object,
      default: () => {
      }
    },
    setInitialDate: {
      type: Boolean,
      default: false
    },
    optionsDates: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
      dp: {
        isOpen: false,
        isLongOpen: false,
        isLongOpenTimeout: null,
        lockScroll: false,
        state: true,
        curPanel: 'date'
      }
    }
  },
  computed: {
    getBlockedDate () {
      return this.blockedDates || []
    },
    model: {
      get () {
        return this.value
      },
      set (value) {
        if (value && Array.isArray(value) && !isValidDate(value[0]) && !isValidDate(value[1])) {
          this.$emit('input', this.range ? [] : null)
          this.$emit('onChangeCallback', this.range ? [] : null)
          return
        }
        this.$emit('input', value)
        this.$emit('onChangeCallback', this.range ? value || [] : value)
      }
    }
  },
  watch: {
    'dp.lockScroll' () {
      if (isWindow()) {
        if (this.dp.lockScroll) {
          document.body.classList.add('v-overflow--date')
        } else {
          document.body.classList.remove('v-overflow--date')
        }
      }
    }
  },
  created () {
    if (this.range) {
      this.input.classes.push('force-fill')
      this.model = [this.startDate, this.endDate]
    }
  },
  mounted () {
    if (this.alwaysOpen) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.input.openPopup()
        })
      })
    }
  },
  methods: {
    setFocus () {
      this.$super(vInput).setFocus()
      this.$refs.input.openPopup()
    },
    clickOnDate () {
      if (this.dp.isLongOpen) {
        this.$refs.input?.closePopup()
      }
    },
    onClick (el = null) {
      switch (el) {
        case 'datepicker':
          break
        default:
          break
      }
    },
    onOpen () {
      this.dp.isOpen = true
      this.dp.isLongOpenTimeout = setTimeout(() => {
        this.dp.isLongOpen = true
      }, 500)
      if (this.$store.state.breakpoint.screen.widthMinCalc <= 768) {
        if (this.alwaysOpen) {
          return
        }
        this.dp.lockScroll = true
      }
    },
    onClose () {
      if (this.alwaysOpen) {
        this.$refs.input.openPopup()
        this.$nextTick(() => {
          if (this.model && this.model[0] && this.model[1] && this.model[0].getDate() === this.model[1].getDate()) {
            const nd = dayjs(this.model[0]).add(7, 'day').toDate()
            this.model = [this.model[0], nd]
          }
        })
        return
      }
      this.$nextTick(() => {
        if (this.model && this.model[0] && this.model[1] && this.model[0].getDate() === this.model[1].getDate()) {
          const nd = dayjs(this.model[0]).add(7, 'day').toDate()
          this.model = [this.model[0], nd]
        }
        clearTimeout(this.dp.isLongOpenTimeout)
        this.dp.lockScroll = false
        this.dp.isOpen = false
        this.dp.isLongOpen = false

        this.$emit('close')
      })
      // if confirm === true
      // this.model = this.$refs.input.currentValue
    },
    onPanelChange (to, from) {
      this.dp.curPanel = to
    },
    closeDp () {
      this.$refs.input.closePopup()
    },
    clearDates () {
      // this.$refs.input.clear()
      this.model = [null, null]
      this.$emit('onChangeCallback', this.range ? [] : null)
    }
  }
}
