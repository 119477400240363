import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      settings: 'settings'
    }),
    getSettingsReviewsCount () {
      return this?.settings?.reviews?.review_count || 30000
    },
    getSettingsReviewAVG () {
      return Math.ceil(this?.settings?.reviews?.average_rating * 10) / 10 || 4.9
    }
  }
}
