import { render, staticRenderFns } from "./main.vue?vue&type=template&id=3a2a204c&scoped=true"
import script from "./main.vue?vue&type=script&lang=js"
export * from "./main.vue?vue&type=script&lang=js"
import style0 from "./main.vue?vue&type=style&index=0&id=3a2a204c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a2a204c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VImg: require('/var/www/tubber-new/components/global/v/img.vue').default})
