//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'vform'
import veeValidate from '~/mixins/veeValidate'

export default {
  name: 'OffersInMailbox',
  mixins: [veeValidate],
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    enabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      busy: {
        form: false
      },
      form: new Form({
        email: ''
      }),
      formRules: {
        email: 'required|email'
      }
    }
  },
  methods: {
    submit () {

    }
  }
}
